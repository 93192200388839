<template>
  <!-- OPERATIONS UNIT READ ONLY IT SELF DATA -->
  <div v-if="$role(['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic'], $store.state.user.role)">
    <v-row v-if="$store.state.product.pricing.data && $store.state.product.pricing_option" no-gutters class="flex-stretch">
      <v-col cols="12" class="col-sm-5 pa-2">
        <v-card class="pa-4 text-center">
          <div class="">
            <div class="d-flex fill-width justify-space-between align-center orange--text text-uppercase font-weight-bold">
              <div class="text-left">
                Updated Price
                <div class="caption">
                  <span class="black--text caption-small">
                    {{ $localDT($store.state.product.pricing.updated.date, 'datetimedefaultdb') }}
                  </span>
                </div>
              </div>
              <div class="d-flex align-center">
                <v-btn color="primary" dark depressed class="rounded-lg text-capitalize" @click.prevent="UpdatePrice('price', $store.state.product.pricing.updated.price, $store.state.product.pricing.updated.price_buyback)">
                  <v-icon class="mr-2">
                    mdi-cash-plus
                  </v-icon>
                  Set new price
                </v-btn>
                <v-btn class="ml-2" icon dark color="purple" @click.prevent="FCM_PUSH()">
                  <v-icon>
                    mdi-bell
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <v-divider class="my-4" />
            <div class="d-flex align-center fill-width">
              <div style="width: 50%;">
                <div class="text-h5 primary--text text-center">
                  Sell : {{ $price($store.state.product.pricing.updated.price) }}
                  <div class="d-flex align-center justify-center caption">
                    <v-icon class="mr-1" x-small>
                      mdi-information
                    </v-icon>
                    Sell is "Buy Price" from customer side
                  </div>
                </div>
              </div>
              <div style="width: 50%;">
                <div class="text-h5 primary--text text-center">
                  Buyback : {{ $price($store.state.product.pricing.updated.price_buyback) }}
                  <div class="d-flex align-center justify-center caption">
                    <v-icon class="mr-1" x-small>
                      mdi-information
                    </v-icon>
                    Buyback is "Sell Price" from customer side
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-4 pa-2">
        <v-card class="pa-4 text-center">
          <div class="">
            <span class="orange--text text-uppercase font-weight-bold">Price Option</span>
            <v-divider class="mt-6 mb-7" />
            <div class="d-flex fill-width justify-space-between align-center">
              <div style="min-width: 33.333333%;">
                <span class="blue--text caption-small">DEFAULT</span>
                <div class="subtitle-1 primary--text d-flex align-center justify-center">
                  {{ $price(parseInt($store.state.product.pricing_option.price_default) || 0) }}
                  <v-icon class="ml-2" color="blue" @click.prevent="UpdatePrice('price_default', $store.state.product.pricing_option.price_default)">
                    mdi-cash-sync
                  </v-icon>
                </div>
              </div>
              <div style="min-width: 33.333333%;">
                <span class="blue--text caption-small">B2B SMALL</span>
                <div class="subtitle-1 primary--text d-flex align-center justify-center">
                  {{ $price(parseInt($store.state.product.pricing_option.price_b2b_small) || 0) }}
                  <v-icon class="ml-2" color="blue" @click.prevent="UpdatePrice('price_b2b_small', $store.state.product.pricing_option.price_b2b_small)">
                    mdi-cash-sync
                  </v-icon>
                </div>
              </div>
              <div style="min-width: 33.333333%;">
                <span class="blue--text caption-small">B2B BIG</span>
                <div class="subtitle-1 primary--text d-flex align-center justify-center">
                  {{ $price(parseInt($store.state.product.pricing_option.price_b2b_big) || 0) }}
                  <v-icon class="ml-2" color="blue" @click.prevent="UpdatePrice('price_b2b_big', $store.state.product.pricing_option.price_b2b_big)">
                    mdi-cash-sync
                  </v-icon>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-3 pa-2">
        <v-card class="pa-4 text-center">
          <div class="">
            <span class="orange--text text-uppercase font-weight-bold">Tax</span>
            <v-divider class="mt-6 mb-7" />
            <div class="d-flex fill-width justify-space-between align-center">
              <div style="min-width: 50%;">
                <span class="blue--text caption-small">TAX (NPWP)</span>
                <div class="subtitle-1 primary--text d-flex align-center justify-center">
                  {{ $store.state.product.pricing_option.price_tax_npwp }}%
                  <v-icon class="ml-2" color="blue" @click.prevent="UpdatePrice('price_tax_npwp', $store.state.product.pricing_option.price_tax_npwp)">
                    mdi-cash-sync
                  </v-icon>
                </div>
              </div>
              <div style="min-width: 50%;">
                <span class="blue--text caption-small">TAX (NONNPWP)</span>
                <div class="subtitle-1 primary--text d-flex align-center justify-center">
                  {{ $store.state.product.pricing_option.price_tax_npwp_none }}%
                  <v-icon class="ml-2" color="blue" @click.prevent="UpdatePrice('price_tax_npwp_none', $store.state.product.pricing_option.price_tax_npwp_none)">
                    mdi-cash-sync
                  </v-icon>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex col-sm-2 py-4 px-2">
        <v-card :class="$role(['logistic'], $store.state.user.role) ? 'primary' : 'grey'" class="d-flex align-center fill-width justify-center px-4 caption font-weight-bold py-2" dark outlined @click.prevent="$role(['logistic'], $store.state.user.role) ? PRODUCT_VIEW() : false">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Product
        </v-card>
      </v-col>
      <v-col cols="12" class="white col-sm-4">
        <div class="px-0 px-sm-4 py-2 py-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            placeholder="Search product"
            prepend-inner-icon="mdi-magnify"
            append-icon="mdi-barcode"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2 white">
        <div class="px-0 px-sm-4 py-2 py-sm-4">
          <v-select
            v-model.number="productBrand"
            :items="[{ name: '- All -', id: 0 }].concat($store.state.brandData)"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            label="Brand"
            :append-icon="parseInt(productBrand) ? 'mdi-pencil-circle-outline' : 'mdi-plus'"
            @click:append="AddBrand()"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2 white">
        <div class="px-0 px-sm-4 py-2 py-sm-4">
          <v-select
            v-model="productCategory"
            :items="[{ name: '- All -', id: 0 }].concat($store.state.product.categoryData)"
            item-text="name"
            item-value="name"
            outlined
            dense
            hide-details
            label="Category"
            :append-icon="productCategory && productCategory !== '- All -' ? 'mdi-pencil-circle-outline' : 'mdi-plus'"
            @click:append="AddCategory($store.state.product.categoryData.find(r => r.name === productCategory))"
          />
        </div>
      </v-col>
      <v-col cols="12" class="white col-sm-2">
        <div class="px-0 px-sm-4 py-2 py-sm-4">
          <v-select
            v-model="productStatus"
            :items="[{ name: 'All Status', value: -1 }, { name: 'Active', value: 1 }, { name: 'Not Active', value: 0 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            label="Status"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 pt-sm-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [10, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.sku`]="{ item }">
            <div>
              <!-- <strong>{{ item.id }}</strong> -->
              <div :class="[parseInt(item.status) ? '' : 'red--text font-italic text-decoration-line-through']" class="caption">
                CODE : {{ item.sku || '-' }}
              </div>
              <small v-if="!parseInt(item.status)" class="red--text font-italic">
                Not Active
              </small>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex justify-space-between align-center py-2" style="min-width: 100px;">
              <div class="d-flex flex-column">
                {{ item.name }}
                <small>
                  Brand: {{ item.brand_name || '-' }}
                </small>
                <small>
                  Category: {{ item.category_name || '-' }}
                </small>
              </div>
            </div>
          </template>
          <template v-slot:[`item.weight`]="{ item }">
            <div class="d-flex justify-end align-center py-2" style="min-width: 50px;">
              {{ item.weight }}gr
            </div>
          </template>
          <template v-slot:[`item.mprice`]="{ item }">
            <div class="d-flex justify-end align-center py-2" style="min-width: 50px;">
              +{{ $price(item.mprice) }}
            </div>
          </template>
          <template v-slot:[`item.pricing`]="{ item }">
            <div class="d-flex justify-end align-center py-2" style="min-width: 100px;">
              <div class="d-flex align-center justify-end flex-wrap">
                <v-chip small color="primary" class="caption mb-1" dark>
                  Buyback : {{ $price($priceOption(item.weight, 0, $store.state.product.pricing.updated.price_buyback, {}).price_buyback) }}
                </v-chip>
                <v-divider vertical class="mx-2" />
                <v-chip small color="primary" class="caption mb-1" dark>
                  Sell : {{ $price($priceOption(item.weight, item.mprice || 0, $store.state.product.pricing.updated.price, $store.state.product.pricing_option).price_default) }}
                </v-chip>
                <v-chip small color="indigo" class="caption mx-1 mb-1"  dark>
                  B2BSMALL: {{ $price($priceOption(item.weight, item.mprice || 0, $store.state.product.pricing.updated.price, $store.state.product.pricing_option).price_b2b_small) }}
                </v-chip>
                <v-chip small color="indigo" class="caption mb-1" dark>
                  B2BBIG: {{ $price($priceOption(item.weight, item.mprice || 0, $store.state.product.pricing.updated.price, $store.state.product.pricing_option).price_b2b_big) }}
                </v-chip>
              </div>
            </div>
          </template>
          <template v-slot:[`item.certificates`]="{ item }">
            <div class="d-flex justify-end align-center py-2" style="min-width: 100px;">
              {{ item.certificates }}
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['logistic'], $store.state.user.role)" :color="$role(['logistic'], $store.state.user.role) ? 'orange' : 'grey'" depressed small dark class="text-capitalize" @click.prevent="$role(['logistic'], $store.state.user.role) ? PRODUCT_VIEW(item, 0, true) : false">
                <v-icon x-small class="mr-2">
                  mdi-pencil
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogProduct.show"
      persistent
      scrollable
      max-width="475"
    >
      <v-card v-if="dialogProduct.data" >
        <v-card-title class="subtitle-1 py-4 justify-center">
          {{ dialogProduct.data.id ? 'Update Product' : 'Create New Product' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-6" style="max-height: 90vh;">
          <v-row class="flex-stretch mb-4">
            <v-col cols="6" class="col-sm-3">
              <div class="fill-width p-relative grey lighten-4 rounded-lg" style="padding-bottom: 100%;">
                <span class="d-flex align-center justify-center" style="position: absolute;top: 0px;left:0px;width: 100%;height: 100%;" @click.prevent="UploadImage(-1)">
                  <v-icon>
                    mdi-image-plus
                  </v-icon>
                </span>
              </div>
            </v-col>
            <v-col v-for="(img, iImg) in $getImageDataAll(dialogProduct.data.images)" :key="'product-image-' + iImg" cols="6" class="col-sm-3 p-relative">
              <v-img
                :aspect-ratio="1"
                :src="img"
                :lazy-src="img"
                width="100%"
                class="rounded-lg grey lighten-4"
                @click.prevent="UploadImage(iImg)"
              />
              <v-icon color="red" class="c-pointer" style="position: absolute;bottom:5px;right:5px;z-index:1;" @click.prevent="RemoveImageProduct(img)">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
          <v-text-field
            v-model="dialogProduct.data.name"
            outlined
            hide-details
            label="Product name"
            class="mb-4"
          />
          <v-select
            v-model.number="dialogProduct.data.brand"
            :items="$store.state.brandData"
            item-text="name"
            item-value="id"
            outlined
            hide-details
            label="Product brand"
            class="mb-4"
          />
          <v-select
            v-model.number="dialogProduct.data.category"
            :items="$store.state.product.categoryData"
            item-text="name"
            item-value="id"
            outlined
            hide-details
            label="Category"
            class="mb-4"
          />
          <v-text-field
            v-model.number="dialogProduct.data.weight"
            outlined
            hide-details
            label="Product weight"
            suffix="gram(s)"
            class="mb-4"
          />
          <v-text-field
            v-model.number="dialogProduct.data.mprice"
            outlined
            hide-details
            label="Price Margin"
            prefix="Rp"
            class="mb-4"
          />
          <v-divider class="mb-2" />
          <div class="mb-4">
            <div class="d-flex align-center grey--text text--darken-2 caption font-weight-bold mb-2">
              <v-icon class="mr-1">
                mdi-cash
              </v-icon>
              Discount Option :
            </div>
            <div>
              <div v-if="!$StrToArray(dialogProduct.data.pricing_option).length" class="pa-2 text-center caption grey--text font-italic">
                - no discount option -
              </div>
              <div v-for="(p, iP) in $StrToArray(dialogProduct.data.pricing_option)" :key="'pricing-option-' + iP" class="p-relative d-flex align-center mb-2">
                <!-- :items="[{ id: -1, name: 'All' }, { id: 0, name: 'DEFAULT' }, { id: 1, name: 'B2BSMALL' }, { id: 2, name: 'B2BBIG' }]" -->
                <v-select
                  :value="p.type"
                  :items="[{ id: 0, name: 'DEFAULT' }, { id: 1, name: 'B2BSMALL' }, { id: 2, name: 'B2BBIG' }]"
                  item-text="name"
                  item-value="id"
                  outlined
                  hide-details
                  dense
                  label="Customer :"
                  class=""
                  @change="DiscountUpdate('type', iP, $event)"
                />
                <v-text-field
                  :value="p.qty"
                  outlined
                  hide-details
                  dense
                  label="Minimum Quantity :"
                  class="mx-2"
                  @keyup="DiscountUpdate('qty', iP, $event)"
                />
                <v-text-field
                  :value="p.discount"
                  outlined
                  hide-details
                  dense
                  label="Discount"
                  prefix="Rp"
                  suffix="/item"
                  class=""
                  @keyup="DiscountUpdate('discount', iP, $event)"
                />
                <v-icon small color="red" style="position: absolute;top: -5px;right: -5px;" @click.prevent="DiscountDelete(iP)">
                  mdi-close-circle
                </v-icon>
              </div>
            </div>
            <div class="d-flex fill-width justify-end align-center">
              <v-btn x-small depressed class="text-capitalize font-weight-bold" @click.prevent="DiscountAdd()">
                <v-icon class="mr-1" x-small>
                  mdi-plus
                </v-icon>
                ADD DISCOUNT
              </v-btn>
            </div>
          </div>
          <v-divider class="mb-4" />
          <v-text-field
            v-model="dialogProduct.data.form"
            outlined
            hide-details
            label="Form"
            class="mb-4"
          />
          <v-text-field
            v-model="dialogProduct.data.fineness"
            outlined
            hide-details
            label="Fineness"
            class="mb-4"
          />
          <v-textarea
            v-model="dialogProduct.data.description"
            outlined
            rows="2"
            hide-details
            label="Product description"
            class="mb-4"
          />
          <v-select
            v-model.number="dialogProduct.data.buyback"
            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            label="Buyback support ?"
            class="mb-4"
          />
          <v-select
            v-model.number="dialogProduct.data.status"
            :items="[{ name: 'Active', value: 1 }, { name: 'Not Active', value: 0 }]"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            label="Status"
            class="mb-4"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogProduct.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            class="px-6 text-capitalize"
            @click="PRODUCT_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogBrand.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogBrand.data" >
        <v-card-title class="subtitle-1 py-4 justify-center">
          {{ dialogBrand.data.id ? 'Update Brand' : 'Create New Brand' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-6" style="max-height: 90vh;">
          <v-text-field
            v-model="dialogBrand.data.name"
            outlined
            hide-details
            label="Brand Name"
            class="mb-4"
          />
          <v-select
            v-model.number="dialogBrand.data.status"
            :items="[{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            label="Publish Brand ?"
            class="mb-4"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogBrand.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialogBrand.data && parseInt(dialogBrand.data.id)"
            color="red darken-1"
            text
            class="text-capitalize mr-4"
            @click="BRAND_PROCESS(true)"
          >
            Remove
          </v-btn>
          <v-btn
            color="primary"
            depressed
            class="px-6 text-capitalize"
            @click="BRAND_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCategory.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogCategory.data" >
        <v-card-title class="subtitle-1 py-4 justify-center">
          {{ dialogCategory.data.id ? 'Update Category' : 'Create New Category' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-6" style="max-height: 90vh;">
          <v-text-field
            v-model="dialogCategory.data.name"
            outlined
            hide-details
            label="Category Name"
            class="mb-4"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogCategory.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialogCategory.data && dialogCategory.data.id"
            color="red darken-1"
            text
            class="text-capitalize mr-4"
            @click="CATEGORY_PROCESS(true)"
          >
            Remove
          </v-btn>
          <v-btn
            color="primary"
            depressed
            class="px-6 text-capitalize"
            @click="CATEGORY_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
let searchTimeout = null
// let searchTimeoutCustomerQ = null
export default {
  name: 'Inventory',
  data: (vm) => ({
    storeBranch: 0,
    productBrand: 0,
    productCategory: '- All -',
    productStatus: -1,
    loadingProcess: false,
    loadingImageUpload: false,
    headers: [
      { text: 'CertCode', value: 'sku', sortable: true },
      // { text: 'SKU', value: 'sku', sortable: false },
      {
        text: 'Product',
        value: 'name'
      },
      {
        text: 'Weight',
        value: 'weight',
        align: 'end'
      },
      {
        text: 'Margin',
        value: 'mprice',
        align: 'end'
      },
      {
        text: 'Price Updated',
        value: 'pricing',
        align: 'end',
        sortable: false
      },
      {
        text: 'Certificates',
        value: 'certificates',
        align: 'end'
      },
      // { text: 'Stock In', value: 'stock_in', sortable: true },
      // { text: 'Stock Out', value: 'stock_out_trx', sortable: true },
      // { text: 'Actual Stock', value: 'stock', sortable: true },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 50,
    tableSearchStatus: '',
    options: {},
    dialogProduct: {
      show: false,
      data: null
    },
    dialogBrand: {
      show: false,
      data: null
    },
    dialogCategory: {
      show: false,
      data: null
    }
  }),
  computed: {
  },
  watch: {
    tableSearch () {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PRODUCT_GET()
      }, 300)
    },
    productStatus () {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PRODUCT_GET()
      }, 300)
    },
    productBrand () {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PRODUCT_GET()
      }, 300)
    },
    productCategory () {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PRODUCT_GET()
      }, 300)
    },
    options () {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PRODUCT_GET()
      }, 300)
    }
  },
  created () {
    this.$store.dispatch('INIT')
    this.PRODUCT_GET()
  },
  methods: {
    DiscountAdd () {
      const data = this.$StrToArray(this.dialogProduct.data.pricing_option)
      data.push({ qty: 0, type: -1, discount: 0 })
      this.dialogProduct.data.pricing_option = this.$ArrayToStr(data)
    },
    DiscountUpdate (key, index, e) {
      const value = key === 'type' ? e : (isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value))
      const data = this.$StrToArray(this.dialogProduct.data.pricing_option)
      data[index][key] = parseInt(value) || 0
      this.dialogProduct.data.pricing_option = this.$ArrayToStr(data)
    },
    DiscountDelete (index) {
      const data = this.$StrToArray(this.dialogProduct.data.pricing_option)
      data.splice(index, 1)
      this.dialogProduct.data.pricing_option = this.$ArrayToStr(data)
    },
    UpdatePrice (type, price, priceBuyback) {
      price = (type).match(/tax/) ? (parseFloat(price) || 0).toFixed(2) : (parseInt(price) || 0)
      const updatedPrice = prompt((type === 'price' ? ('Sell price :') : ('Set new "' + type + '" :')), price)
      if (updatedPrice && parseFloat(updatedPrice) > 0) {
        if (type === 'price') {
          const updatedPriceBuyback = prompt('Buy Price', priceBuyback)
          if (updatedPriceBuyback && parseFloat(updatedPriceBuyback) > 0) {
            const msg = 'Sell : ' + (this.$price(updatedPrice)) + ' & Buyback : ' + (this.$price(updatedPriceBuyback)) + ' ?'
            if (!confirm(msg)) {
              return false
            }
            this.$store.dispatch('product/PRODUCT_PRICING_PROCESS', {
              price_buyback: updatedPriceBuyback,
              price: updatedPrice
            }).then((res) => {
              console.log('price: ', res)
              this.$store.dispatch('product/PRODUCT_PRICING_GET')
            })
          }
        } else {
          const payload = { id: 1 }
          payload[type] = updatedPrice
          this.$store.dispatch('product/PRODUCT_PRICING_SET_PROCESS', payload).then((res) => {
            console.log('price ' + type + ': ', res)
            this.$store.dispatch('product/PRODUCT_PRICING_SET_GET')
          })
        }
      }
    },
    AddBrand () {
      const brand = parseInt(this.productBrand) ? this.$store.state.brandData.find(r => parseInt(r.id) === parseInt(this.productBrand)) : {
        name: '',
        code: '',
        logo: '',
        status: 1
      }
      this.dialogBrand.data = Object.assign({}, brand)
      this.dialogBrand.show = true
    },
    BRAND_PROCESS (isRemove) {
      const data = Object.assign({}, this.dialogBrand.data)
      if (isRemove) {
        if (!confirm('Remove brand ?')) {
          return false
        }
        data.status = 100
      } else {
        if (!confirm('Save brand ?')) {
          return false
        }
        if (!data.name) {
          this.$store.dispatch('TOAST', { show: true, message: 'Please input brand name!' })
          return false
        }
      }
      this.$store.dispatch('product/PRODUCT_BRAND_PROCESS', data)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Success.' })
            this.$store.dispatch('INIT')
            this.dialogBrand.show = false
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Failed, please try again!' })
          }
        })
    },
    AddCategory (v) {
      this.dialogCategory.data = v ? Object.assign({}, v) : {
        name: '',
        icon: ''
      }
      this.dialogCategory.show = true
    },
    CATEGORY_PROCESS (isRemove) {
      const data = Object.assign({}, this.dialogCategory.data)
      if (parseInt(data.id) && isRemove) {
        data.status = 100
        if (!confirm('Remove Category ?')) {
          return false
        }
      } else {
        if (!confirm('Save Category ?')) {
          return false
        }
      }
      if (!data.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please input category name!' })
        return false
      }
      this.$store.dispatch('product/PRODUCT_CATEGORY_PROCESS', data)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Success.' })
            this.$store.dispatch('INIT')
            this.dialogCategory.show = false
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Failed, please try again!' })
          }
        })
    },
    PRODUCT_VIEW (product) {
      const data = product ? Object.assign({}, product) : Object.assign({}, {
        sku: '',
        brand: 0,
        category: 0,
        name: '',
        images: '[]',
        weight: 0.0,
        pricing_option: '[]',
        mprice: 0,
        form: '',
        fineness: '',
        description: '',
        buyback: 0,
        status: 1
      })
      data.pricing_option = data.pricing_option || '[]'
      data.images = data.images || '[]'
      this.dialogProduct.data = data
      this.dialogProduct.show = true
    },
    PRODUCT_PROCESS () {
      const product = Object.assign({}, this.dialogProduct.data)
      if (!product.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid product name!' })
        return false
      }
      if (!parseFloat(product.weight)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid product weight!' })
        return false
      }
      this.$store.dispatch('product/PRODUCT_PROCESS', product)
        .then((res) => {
          if (res.status) {
            this.dialogProduct.show = false
            this.$store.dispatch('TOAST', { show: true, message: 'Success!' })
            this.PRODUCT_GET(this.tableSearch)
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    PRODUCT_GET () {
      const q = this.tableSearch ? ('&q=' + (this.tableSearch || '')) : ''
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const withSortBy = sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : ''
      const withStatus = this.productStatus > -1 ? ('&status=' + this.productStatus) : ''
      const withBrand = (parseInt(this.productBrand) ? ('&brand=' + parseInt(this.productBrand)) : '')
      const withCategory = '&category=' + (this.productCategory && this.productCategory !== '- All -' ? this.productCategory : '')
      const query = '?x=' + q + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '')
      this.table = []
      this.tableTotal = 0
      this.tableLoading = true
      this.$store.dispatch('product/PRODUCT_GET', query + withSortBy + withStatus + withBrand + withCategory)
        .then((res) => {
          this.tableLoading = false
          if (res.status) {
            this.table = res.data.data.data || []
            this.tableTotal = parseInt(res.data.data.total) || 0
          }
        })
    },
    FCM_PUSH () {
      const title = prompt('Notification title :', '')
      if (!title) {
        return false
      }
      const body = prompt('Notification message :', '')
      if (!body) {
        return false
      }
      this.$store.dispatch('FCM_PUSH', {
        title,
        body
      })
        .then((res) => {
          console.log(res)
        })
    },
    UploadImage (index) {
      const length = this.$getImageDataAll(this.dialogProduct.data.images).length
      if (length >= 3 && index === -1) {
        this.$store.dispatch('TOAST', { show: true, message: 'Maximum images = 3!' })
        return false
      }
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e, index)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event, index) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: 'product'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                const images =  $.$getImageDataAll($.dialogProduct.data.images)
                if (parseInt(index) >= 0) {
                  images[parseInt(index)] = resUpload.data.data.secure_url
                } else {
                  images.push(resUpload.data.data.secure_url)
                }
                $.dialogProduct.data.images = $.$ArrayToStr(images)
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Upload failed, please try again!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    },
    RemoveImageProduct (img) {
      const imgs = this.$getImageDataAll(this.dialogProduct.data.images)
      const imgIndex = imgs.findIndex(r => r === img)
      if (imgIndex > -1) {
        imgs.splice(imgIndex, 1)
        this.dialogProduct.data.images = this.$ArrayToStr(imgs)
      }
    }
  }
}
</script>

<style lang="scss">
.gmj-gallery {
  >.gmj-gallery-action {
    transition: opacity .3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    >.gmj-gallery-action {
      visibility: visible;
      pointer-events: all;
      opacity: 1;
    }
  }
}
.table-pbj {
  position: relative;
  min-width: 300px;
  overflow-x: auto;
  .pb-active {
    td {
      background: rgba(1, 4, 182, 0.1);
    }
  }
  td {
    padding: 2px 4px;
  }
  thead {
    td {
      padding: 4px;
      text-align: center;
      font-size: 10px;
      font-weight: bold;
    }
  }
  tbody {
    td {
      border-bottom: solid 1px rgba(0, 0, 0, .1);
    }
  }
}
</style>
